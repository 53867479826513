<template>
    <div>
       <pm-Card>
            <template v-slot:title>
                <h4 style="text-align:center;">Confirmação de Dados do Formulário </h4>
                <div class="grid grid-nogutter justify-content-between" style="margin-top:10px">
                    <!-- <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <pm-Button label="Enviar" @click="enviarDados()" icon="pi pi-check" iconPos="right" class="p-button-success"/> -->
                </div>
            </template>
            <template v-slot:content>
                <div class="p-fluid formgrid grid" style="font-size:12px">
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">CPF: &nbsp; <b>{{ info.cpf ? info.cpf : ' Não Informado '}}</b></label>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Nascimento: &nbsp; </label>
                        <b v-if="info.dataNascimento">{{this.nasc}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Sexo: &nbsp; </label>
                        <b v-if="info.sexo =='M'">Masculino</b>
                        <b v-else>Feminino</b>
                    </div>
                    <div class="field col-12" style="margin:0px;"></div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Nome: &nbsp; <b>{{ info.nome ? info.nome : ' Não Informado '}} {{ info.sobrenome ? info.sobrenome : '  '}}  </b> </label>
                    </div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;">
                        <label for="class">Nome da mãe: &nbsp; <b>{{ info.nomeMae ? info.nomeMae : ' Não Informado '}}</b></label>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;">
                        <label for="class">Nome do pai: &nbsp; </label>
                        <b v-if="info.nomePai" >{{ info.nomePai }}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Nome Responsável do Aluno: &nbsp; <b>{{ info.responsavel ? info.responsavel : ' Não Informado '}}</b></label>
                    </div>

                    <div class="field col-12" style="margin: 10px;"></div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 6px;">
                        <label for="class">Autorização para uso de imagem: &nbsp; </label>
                        <b v-if="info.usoDaImage == 1" > sim</b>
                        <b v-else> não </b>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Nacionalidade: &nbsp; </label>
                        <b v-if="info.nacionalidade">{{info.nacionalidade}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Naturalidade: &nbsp; </label>
                        <b v-if="info.naturalidade">{{info.naturalidade}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Cor/raça: &nbsp; </label>
                        <b v-if="info.cor">{{info.cor}}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Tipos sanguíneo: &nbsp; </label>
                        <b v-if="info.tipo_sanguineo">{{info.tipo_sanguineo}}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12" style="margin: 0px;"></div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Religião: &nbsp; </label>
                        <b v-if="info.religiao">{{info.religiao}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-4" style="margin:0px;">
                        <label for="class">Frequentará aulas de ensino religioso: &nbsp; </label>
                        <b v-if="info.aulas_religiao_frequentara == 1">sim</b>
                        <b v-else> não </b>
                    </div>



                    <div class="field col-12" style="margin:10px;"></div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Documento de Identidade: &nbsp; </label>
                        <b v-if="info.rg">{{info.rg}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Orgão emissor: &nbsp; </label>
                        <b v-if="info.orgao_emissor">{{info.orgao_emissor}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">UF: &nbsp; </label>
                        <b v-if="info.uf_rg">{{info.uf_rg}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label for="class">Data de emissão: &nbsp; </label>
                        <b v-if="info.rgDataEmissao">{{data_emi}}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12" style="margin:0px;"></div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label >Estado civil: &nbsp; </label>
                        <b v-if="info.estadocivil">{{info.estadocivil}}</b>
                        <b v-else> {{info.estadocivil}} </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 6px;" v-if="info.estadocivil == 'Solteiro'">
                        <label for="class">Certidão de nascimento: &nbsp; </label>
                        <b v-if="info.certidaoNascimento">{{info.certidaoNascimento}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 6px;" v-if="info.estadocivil == 'Casado'">
                        <label>Certidão de casamento: &nbsp; </label>
                        <b v-if="info.certidaoCasamento">{{info.certidaoCasamento}}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;" v-if="info.estadocivil == 'Solteiro'">
                        <label >Livro: &nbsp;</label>
                        <b v-if="info.livro_rg">{{info.livro_rg}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;" v-if="info.estadocivil == 'Solteiro'">
                        <label >Folha: &nbsp;</label>
                        <b v-if="info.folha_rg">{{info.folha_rg}}</b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;" v-if="info.estadocivil == 'Solteiro'">
                        <label >Termo: &nbsp;</label>
                        <b v-if="info.termo_rg">{{info.termo_rg}}</b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12" style="margin:0px;"></div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin-bottom: 6px;">
                        <label>Alérgico(a): &nbsp;</label>
                        <b v-if="info.alergico == 1"> sim </b>
                        <b v-else> não </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 6px;" v-if="info.alergico == 1">
                        <label>Tipo de alergia: &nbsp;</label>
                        <b v-if="info.tipo_alergia"> {{info.tipo_alergia}} </b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin-bottom: 6px;" v-if="info.possuiDeficiencia == 1">
                        <label>O aluno necessita de atendimento especial(NEE): &nbsp;</label>
                        <b v-if="info.nee == 1"> sim </b>
                        <b v-else> não </b>
                    </div>

                    <div class="field col-12" style="margin:0px;"></div>

                    <div class="field col-12 md:col-12 lg:col-12" style="margin:0px;">

                        <div style="margin:0px;" v-if="info.possuiDeficiencia == 1">
                            <label>Qual à Necessidade especial: &nbsp;</label>
                            <b v-if="info.deficiencia"> {{info.deficiencia}} </b>
                            <b v-else> Não Informado </b>
                        </div>
                    </div>


                    <div class="field col-12"  style="margin:10px;"></div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;" >
                        <label>Possui dispositivo com acesso à internet: &nbsp;</label>
                        <b v-if="info.conectividade == 1" > sim </b>
                        <b v-else> não </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;" >
                        <label>Dispositivos: &nbsp;
                        <div v-if="info.tipos_dispotivos.length>0" style="vertical-align:middle !important">
                            <b  v-for="n in info.tipos_dispotivos"  :key="n"><span class="badge rounded-pill bg-dark" style="margin-right:5px;"> {{n}} </span> </b>
                        </div>
                        <b v-else> Não Informado </b>
                        </label>
                    </div>

                   <div class="field col-12"  style="margin:10px;"></div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;">
                        <label>Email: &nbsp;</label>
                        <b v-if="info.email" > {{info.email}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;" >
                        <label>CEP: &nbsp;</label>
                        <b v-if="info.cep" > {{info.cep}} </b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;">
                        <label>Logradouro: &nbsp;</label>
                        <b v-if="info.logradouro" > {{info.logradouro}} </b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;" >
                        <label>Numero: &nbsp;</label>
                        <b v-if="info.numero" > {{info.numero}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label>Complemento: &nbsp;</label>
                        <b v-if="info.complemento" > {{info.complemento}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3"  style="margin:0px;">
                        <label>Bairro: &nbsp;</label>
                        <b v-if="info.bairro" > {{info.bairro}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-3 lg:col-3"  style="margin:0px;">
                        <label>Cidade: &nbsp;</label>
                        <b v-if="info.cidade" > {{info.cidade}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12 md:col-2 lg:col-2"  style="margin:0px;">
                        <label>Estado: &nbsp;</label>
                        <b v-if="info.estado" > {{info.estado}} </b>
                        <b v-else> Não Informado </b>
                    </div>
                    <div class="field col-12" style="margin:0px;"></div>
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label>Utiliza transporte: &nbsp;</label>
                        <b v-if="info.utiliza_transporte == 1" > sim </b>
                        <b v-else> não </b>
                    </div>
                    <div class="field col-12 md:col-4 lg:col-4" style="margin:0px;">
                        <label>Qual transporte: &nbsp;</label>
                        <b v-if="info.qual_transporte" > {{info.qual_transporte}} </b>
                        <b v-else> Não Informado </b>
                    </div>

                    <div class="field col-12 md:col-12 lg:col-12" style="margin:0px;">
                      <label>Telefones: &nbsp;</label>
                      <p v-for="tel in info.telefone">
                        <b> {{ tel.nome}}  ( {{ tel.identificador }} ) </b>
                      </p>
                    </div>

                </div>

            </template>
            <template v-slot:footer>
                <div class="grid grid-nogutter justify-content-between">
                    <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <pm-Button label="Enviar" @click="enviarDados()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
                </div>
            </template>
        </pm-Card>
    </div>
</template>


<script>

export default {
    props: {
      dadosPessoa: { type: Array, default: () => [] },
      pg:{}
    },
    data () {
        return {
            info: {
                nome: '',
                nomeMae: '',
                nomePai: '',
                possuiDeficiencia: false,
                deficiencia: '',
                nee:false,
                observacao:'',
                email:'',
                senha:'',
                estadocivil:'Solteiro',
                certidaoCasamento:'',
                certidaoNascimento:'',
                rg:'',
                dataEmissao:'',
                cpf:'',
                dataNascimento:'',
                sexo:'',
                cep:'',
                logradouro:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                telefone:[],
            },
            nasc:'',
            data_emi:'',
            cepInfo:'',
            submitted: false,
            validationErrors: {}
        }
    },
    methods: {
        prevPage(info) {
            this.$emit('prevPage', {info: this.info});
        },
        enviarDados(info) {
            this.$emit('enviarDados', {info: this.info});
        },
    },
    beforeMount() {
        this.info.nome =   (this.dadosPessoa.nome != undefined ? this.dadosPessoa.nome : this.info.nome);
        this.info.sobrenome = (this.dadosPessoa.sobrenome != undefined ? this.dadosPessoa.sobrenome : this.info.sobrenome);
        this.info.nomeMae = (this.dadosPessoa.nomeMae != undefined ? this.dadosPessoa.nomeMae : this.info.nomeMae);
        this.info.nomePai = this.dadosPessoa.nomePai;
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia =  this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.email =  this.dadosPessoa.email;
        this.info.emailPadrao =  this.dadosPessoa.emailPadrao;
        this.info.senha =  this.dadosPessoa.senha;
        this.info.confirmarSenha =  this.dadosPessoa.confirmarSenha;
        this.info.estadocivil =  (this.dadosPessoa.estadocivil != undefined ? this.dadosPessoa.estadocivil : this.info.estadocivil );
        this.info.certidaoNascimento =  this.dadosPessoa.certidaoNascimento;
        this.info.certidaoCasamento =  this.dadosPessoa.certidaoCasamento;
        this.info.rg =  this.dadosPessoa.rg;
        this.info.rgDataEmissao = (this.dadosPessoa.rgDataEmissao != undefined ? this.dadosPessoa.rgDataEmissao : this.info.rgDataEmissao);
        this.info.cpf =  this.dadosPessoa.cpf;
        this.info.cpfDele = this.dadosPessoa.cpfDele;
        this.info.dataNascimento =  (this.dadosPessoa.dataNascimento != undefined ? this.dadosPessoa.dataNascimento : this.info.dataNascimento);
        this.info.cep =  (this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep);
        this.info.estado =  this.dadosPessoa.estado;
        this.info.cidade =  this.dadosPessoa.cidade;
        this.info.bairro =  this.dadosPessoa.bairro;
        this.info.logradouro =  this.dadosPessoa.logradouro;
        this.info.numero =  this.dadosPessoa.numero;
        this.info.complemento =  this.dadosPessoa.complemento;
        this.info.telefone =  (this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone);
        this.info.sexo =  (this.dadosPessoa.sexo != undefined ? this.dadosPessoa.sexo : this.info.sexo );
        this.info.foto =  this.dadosPessoa.foto;
        this.info.id =  (this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id);
        this.info.login_id =  (this.dadosPessoa.login_id != undefined ? this.dadosPessoa.login_id : this.info.login_id);
        this.info.matricula = this.dadosPessoa.matricula;
        this.info.alergico = (this.dadosPessoa.alergico != undefined ? this.dadosPessoa.alergico : this.info.alergico);
        this.info.tipo_alergia = (this.dadosPessoa.tipo_alergia != undefined ? this.dadosPessoa.tipo_alergia : this.info.tipo_alergia);
        this.info.nacionalidade = (this.dadosPessoa.nacionalidade != undefined ? this.dadosPessoa.nacionalidade : this.info.nacionalidade);
        this.info.naturalidade = (this.dadosPessoa.naturalidade != undefined ? this.dadosPessoa.naturalidade : this.info.naturalidade);
        this.info.cor = (this.dadosPessoa.cor != undefined ? this.dadosPessoa.cor : this.info.cor);
        this.info.religiao = (this.dadosPessoa.religiao != undefined ? this.dadosPessoa.religiao : this.info.religiao);
        this.info.aulas_religiao_frequentara = (this.dadosPessoa.aulas_religiao_frequentara != undefined ? this.dadosPessoa.aulas_religiao_frequentara : "0");
        this.info.usoDaImage = (this.dadosPessoa.usoDaImage != undefined ? this.dadosPessoa.usoDaImage : "0");
        this.info.responsavel = (this.dadosPessoa.responsavel != undefined ? this.dadosPessoa.responsavel : "");
        this.info.orgao_emissor = (this.dadosPessoa.orgao_emissor != undefined ? this.dadosPessoa.orgao_emissor : "");
        this.info.uf_rg = (this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "");
        this.info.livro_rg = (this.dadosPessoa.livro_rg != undefined ? this.dadosPessoa.livro_rg : "");
        this.info.termo_rg = (this.dadosPessoa.termo_rg != undefined ? this.dadosPessoa.termo_rg : "");
        this.info.folha_rg = (this.dadosPessoa.folha_rg != undefined ? this.dadosPessoa.folha_rg : "");
        this.info.conectividade = (this.dadosPessoa.conectividade != undefined ? this.dadosPessoa.conectividade : "1");
        this.info.tipos_dispotivos = (this.dadosPessoa.tipos_dispotivos != undefined ? this.dadosPessoa.tipos_dispotivos : []);
        this.info.tipo_sanguineo = (this.dadosPessoa.tipo_sanguineo != undefined ? this.dadosPessoa.tipo_sanguineo : '');
        this.info.utiliza_transporte =  (this.dadosPessoa.utiliza_transporte != undefined ? this.dadosPessoa.utiliza_transporte : '1');
        this.info.qual_transporte =  (this.dadosPessoa.qual_transporte != undefined ? this.dadosPessoa.qual_transporte : '');
        this.info.estadocivil = (this.dadosPessoa.estadocivil != undefined ? this.dadosPessoa.estadocivil : 'Solteiro');
        this.info.emailDele =this.dadosPessoa.emailDele;

        let r = this.info.dataNascimento.split("-");
        this.nasc = r[2]+'/'+r[1]+'/'+r[0];

        let s = this.info.rgDataEmissao.split("-");
        this.data_emi = s[2]+'/'+s[1]+'/'+s[0];
    },
}
</script>
